import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="form-container">
        <iframe
          className="form"
          title="Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSeRKiR4jW2FYhj57hvjhJcunLDTdk_3juJwB6ai10Un5bTfIw/viewform?embedded=true"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default App;
